import { Badge } from "reactstrap";
import { MOBILE_RECHARGE_OPERATORS } from "../constants/billPayment.constant";

export const filterOperatorsListByType = (operators, operatorType) => (operators.filter((obj) => obj.payadda_operatortype === operatorType));
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const splitTxnComment = (tx_comments, orderId) => {
    if (orderId && tx_comments.indexOf(orderId) !== -1) {
        // console.log(tx_comments,orderId)
        let len = orderId.toString().length + 2;
        tx_comments = tx_comments.slice(len);
        tx_comments = (tx_comments.indexOf("-") === 1) ? tx_comments.slice(2) : tx_comments;
        return tx_comments;
    }
    return tx_comments;

}


// Following function help to extract operation name from selected mobile recharge types

export const extractMobileOperatorFromRechargeType = (rechargeType) => {
    rechargeType = rechargeType.toLowerCase();
    const plan = MOBILE_RECHARGE_OPERATORS["plans"].filter(value => rechargeType.includes(value.toLowerCase()))[0];
    const offer = MOBILE_RECHARGE_OPERATORS["offers"].filter(value => rechargeType.includes(value.toLowerCase()))[0]
    return { plan, offer }
}


// Following function help to comparision beetween dynamic dth dynamic api response name with statics

export const isObjEmpty = obj => Object.keys(obj).length === 0


export const colorStatus = (status, userClass = '') => {
    switch (status.toLowerCase()) {
        case 'processed':
            return (
                <Badge pill color='success' className={`${userClass}`}>
                    SUCCESS
                </Badge>
            )

        case 'success':
        case 'active':
        case 'valid':
            return (
                <Badge pill color='success batm-light-success' className={`${userClass}`}>
                    {status.toUpperCase()}
                </Badge>
            )

        case 'failed':
        case 'suspended':
            return (
                <Badge pill color='danger' className={`${userClass}`}>
                    {status.toUpperCase()}
                </Badge>
            )

        case 'pending':
        case 'inactive':
            return (
                <Badge pill color='primary' className={`${userClass}`}>
                    {status.toUpperCase()}
                </Badge>
            )

        case 'queued':
        case 'processing':
            return (
                <Badge pill color='light-warning' className={`${userClass}`}>
                    {status.toUpperCase()}
                </Badge>
            )

        default:
            return (status)
    }
}


export const txnStatus = (status, userClass = '') => {
    switch (status?.toLowerCase()) {
        case 'success':
        case 'active':
        case 'valid':
        case 'processed':
        case 'SUCCESS':
        case 'S':
        case 's':

            return (
                <Badge pill color='success' className={`${userClass}`}>
                    Success
                </Badge>
            )
        case 'failed':
        case 'suspended':
        case 'inactive':
        case 'FAILED':
        case 'F':
        case 'f':
        case 'FAILURE':
        case 'failure':
            return (
                <Badge pill color="danger" className={`${userClass}`}>
                    {/* {status.toUpperCase()} */}
                    Failed
                </Badge>
            )
        case 'CANCELLED':
        case 'cancelled':
            return (
                <Badge pill color="danger" className={`${userClass}`}>
                    {/* {status.toUpperCase()} */}
                    Cancelled
                </Badge>
            )
        case 'pending':
        case 'processing':
        case 'in_progress':
        case 'in-progress':
        case 'queued':
        case 'process':
        case 'PROCESS':
        case 'p':
        case 'P':
            return (
                <Badge pill color='warning' className={`${userClass}`}>
                    {/* {status.toUpperCase()} */}
                    In Progress
                </Badge>
            )
        // case 'queued':
        //     return (
        //         <Badge pill color='warning' className={`${userClass}`}>
        //             {status.toUpperCase()}
        //         </Badge>
        //     )
        default:
            return (status)
    }
}


export const commonTxnStatus = (status) => {
    let ret = '';
    switch (status?.toLowerCase()) {
        case 'success':
        case 'SUCCESS':
        case 'S':
        case 'processed':
        case 's':
            ret = ('Success');
            break;
        case 'failed':
        case 'FAILED':
        case 'F':
        case 'f':
        case 'FAILURE':
        case 'failure':
            ret = ('Failed');
            break;
        case 'in_progress':
        case 'queued':
        case 'pending':
        case 'process':
        case 'PROCESS':
        case 'p':
        case 'P':
        case 'processing':
            ret = ('In Progress');
            break;
        case 'cancelled':
        case 'CANCELLED':
            ret = ('Cancelled');
            break;

        default:
            ret = status;
    }
    return ret;
}


export const pullBalanceFromArray = (array, type) => {
    // console.log('array', array)
    if (array)
        return array.filter(item => item.type === type)
    else
        return []
}



export const getDate = (date = null) => {
    let dateObj
    if (date) {
        dateObj = new Date(date)
    } else {
        dateObj = new Date()
    }

    const dd = String(dateObj.getDate()).padStart(2, '0')
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0')
    const yyyy = dateObj.getFullYear()
    return `${yyyy}-${mm}-${dd}`
}

export const isShowStatusCheckBtn = (date, setDateLimit) => {
    const previousDate = new Date();
    previousDate.setDate(previousDate.getDate() - setDateLimit);

    const previousDateFormated = new Date(getDate(previousDate))
    const prevDate = previousDateFormated.getTime()

    const txnDateFormated = new Date(date)
    const txnDate = txnDateFormated.getTime()

    if (txnDate >= prevDate) {
        return true
    }

    return false
}


export const getDateTime = (date = null) => {
    let dateObj
    if (date) {
        dateObj = new Date(date)
    } else {
        dateObj = new Date()
    }

    const dd = String(dateObj.getDate()).padStart(2, '0')
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0')
    const yyyy = dateObj.getFullYear()

    const hh = String(dateObj.getHours()).padStart(2, '0')
    const mi = String(dateObj.getMinutes()).padStart(2, '0')
    const ss = String(dateObj.getSeconds()).padStart(2, '0')
    return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`
}