import { createSlice } from "@reduxjs/toolkit";

export const walletBalanceSlice = createSlice({
    name: 'allWalletBalance',
    initialState: {
        balances: []
    },
    reducers: {
        setWalletBalance(state, action) {
            state.balances = action.payload;
            // console.log('state.balance', state.balances)
        }
    }
})

export const walletActions = walletBalanceSlice.actions;