//user_agent.js
const platform = require('platform');


export const userAgentInfo = () => {

    const info = {
        os: platform?.os?.family,
        osVersion: platform?.os?.version,
        browser: platform?.name,
        browserVersion: platform?.version,
        manufacturer: platform?.manufacturer,
        device: platform?.product
    }

    return info
}