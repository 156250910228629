import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

export const useGeoLocation = () => {
    const [location, setLocation] = useState({
        isLoading: true,
        error: '',
        coordinates: { lattitude: '', longitude: '' }
    });


    const onSuccess = (info) => {
        setLocation(() => ({
            isLoading: false,
            error: '',
            coordinates: {
                lattitude: info.coords.latitude.toString(),
                longitude: info.coords.longitude.toString()
            }
        }))
    }


    const onError = (error) => {
        setLocation(() => ({
            isLoading: false,
            error,
            coordinates: { lattitude: '', longitude: '' }
        }))
    }

    useEffect(() => {
        if (!("geolocation" in navigator)) {
            onError({
                status: 0,
                message: 'Geolocation is not supported'
            })
            toast('Geolocation is not supported', { type: 'error' });
        } else {
            navigator.geolocation.getCurrentPosition(
                onSuccess,
                ({ message, code }) => { onError({ status: 201, message }) },
                { enableHighAccuracy: true })
        }
    }, [])


    return [location, setLocation];
}

