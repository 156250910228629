export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseUrlKyc = process.env.REACT_APP_BASE_URL_KYC

export const apiUrls = {
    sanctum: '/sanctum/csrf-cookie',
    logout: '/api/auth/logout',
    tpin: {
        request: '/api/v1/payment/tpin/set',
        verify: '/api/v1/payment/tpin/verify/otp'
    },
    payment: {
        init: '/api/v1/payment/authorize',
        complete: '/api/v1/payment/process'
    },
    onBoarding: {
        step_0: {
            sendOtp: '/api/auth/otp/send',
            verifyOtp: '/api/auth/otp/verify'
        },
        step_1: {
            onBoard: '/api/auth/onboard/user'
        },
        step_2: {
            aadhaarOtp: '/api/auth/onboard/user/aadhaar',
            verifyAadhaarOtp: '/api/auth/onboard/user/aadhaar/verify',
            panOtp: '/api/auth/onboard/user/pan',
            verifyPanOtp: '/api/auth/onboard/user/pan/verify'
        },
        step_3: {
            address: '/api/auth/onboard/user/final/submit'
        },
        shopInfo: '/api/v1/user/business/profile'
    },
    referral: {
        ref: '/api/v1/user/referral'
    },
    insurance: {
        init: '/api/v1/service/insurance/init'
    },
    aeps: {
        onBoarding: '/api/v1/service/aeps/onboard/merchant',
        kycDoc: '/api/v1/user/kyc/docs',
        sendOtp: '/api/v1/service/aeps/send/otp',
        submitOtp: '/api/v1/service/aeps/validate/otp',
        bioMetric: '/api/v1/service/aeps/biometric'
    },
    pan: {
        uti: {
            userOnboard: '/api/v1/service/pan/agent',
            init: '/api/v1/service/pan/init',
            status: '/api/v1/service/pan/status',
            txnHistory: '/api/v1/report/pan'
        }
    },
    dmt: {
        addOutlet: '/api/v1/service/dmt/outlet',
        verifyOutlet: '/api/v1/service/dmt/outlet/verify',
        fetchRemitter: '/api/v1/service/dmt/remitter',
        updateRemitter: '/api/v1/service/dmt/remitter/verify',
        addBeneficiary: '/api/v1/service/dmt/beneficiary',
        verifyOtpDeleteBeneficiary: '/api/v1/service/dmt/beneficiary/verify',
        bankList: '/api/v1/service/dmt/banks',
        transactions: '/api/v1/report/dmt',
        txnStatus: '/api/v1/service/dmt/status'
    },
    offers: {
        all: '/api/v1/offers'
    },
    aepsTxn: {
        be: '/api/v1/service/aeps/be',
        ms: '/api/v1/service/aeps/ms',
        cw: '/api/v1/service/aeps/cw',
        aepshistory: '/api/v1/report/aeps',
        aepsCheckStatus: '/api/v1/service/aeps/txn/status'
    },
    oldReport: {
        aeps: '/api/v1/report/aeps/old',
        matm: '/api/v1/report/microatm/old',
        settlement: '/api/v1/report/settlement/old',
        ledger: '/api/v1/report/ledger/old'
    },
    adhaarPay: {
        adhaarpayTxn: '/api/v1/service/aeps/ap'
    },
    Matm: {
        Matmhistory: '/api/v1/report/microatm',
        mataStatusCheck: '/api/v1/service/microatm/status/check'
    },
    dashboard: {
        init: '/api/v1/app/configuration',
        // init: '/api/v1/dashboard/appSettings',
        balance: '/api/v1/dashboard/balances'
    },
    bank: {
        add: '/api/v1/user/bank/add',
        list: '/api/v1/user/bank',
        status: '/api/v1/user/bank/verify',
        setDefault: '/api/v1/user/bank/set/default'
    },
    recharge: {
        plans: '/api/v1/service/recharge/plansandoffers',
        offerPlan: '/api/v1/service/recharge/plansandoffers',
        getOperator: '/api/v1/service/recharge/operators',
        recharge: '/api/auth/service/recharge/recharge',
        txnhistory: '/api/v1/report/recharge',
        checkstatus: '/api/v1/service/recharge/status',
        circle: '/api/v1/service/recharge/circles'
    },
    dth: {
        plans: '/api/v1/service/recharge/plansandoffers',
        offerPlan: '/api/v1/service/recharge/plansandoffers',
        customerInfo: '/api/v1/service/recharge/dth/customer/info',
        heavyRefresh: '/api/v1/service/recharge/dth/refresh',
        getOperator: '/api/v1/service/recharge/operators',
    },
    settlement: {
        order: '/api/auth/service/settlement/order',
        charges: '/api/v1/service/settlement/charges',
        orderHistory: '/api/v1/report/settlement'
    },
    commom: {
        getOperator: '/api/v1/service/recharge/operators',
        stateList: '/api/common/state',
        cityList: '/api/common/city',
        districtList: '/api/common/district',
        bankList: '/api/common/bank',
        bizCategory: '/api/common/business/category',
        ping: '/api/ping',
        support: '/api/common/support',
        feeCommission: '/api/v1/dashboard/feeandcommision',
        feeAndCommission: '/api/v1/dashboard/fee/commisions'
    },
    wallet: {
        walletHistory: '/api/v1/report/ledger'
    },
    xettle: {
        be: '/api/v1/service/aeps/getBalance'
    }
}


export const apiStatusCodes = {
    success: '200',
    successX: '0x0200',
    failed: '202',
    missing: '203',
    timeout: '204',
    somethingWrong: '205',
    pending: '206',
    reversed: '207',
    warning: '208'
}


export const apiStatusText = {
    maintenance: 'maintenance'
}


export const API_AREA_VALUE = 'web';