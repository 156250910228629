import { useState, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { toast } from "react-toastify";

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormFeedback, Input } from "reactstrap";
import { isObjEmpty } from "../../../../helpers/utils";
import { apiUrls } from "../../../../constants/apiUrls";
import { DEVICE_Id, NOCAPTCHA_SITEKEY } from "../../../../constants/defaultValues";
import { postRequest } from "../../../../services/httpService";
import ReCAPTCHA from 'react-google-recaptcha';
import { useGeoLocation } from "../../../../hooks/useGeoLocation";
// import { GoogleReCaptcha } from "react-google-recaptcha-v3";


const defaultValues = {
    mobile: ''
}


const formSchema = yup.object().shape({
    mobile: yup.string().trim().length(10, 'This must be 10 digits')
        .matches(/^([1-9]){1}([0-9]){9}?$/, { message: 'This must be a valid number' })
        .required('This is required')
});

const gToken = { value: null }

const Auth = (props) => {

    const { setIsLoading, setLoginStep, setLoginResponse } = props

    const [formBtnText, setFormBtnText] = useState('Get started')
    const [recaptchaErr, setRecaptchaErr] = useState('')
    const captchaRef = useRef(null)
    const [location] = useGeoLocation();


    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(formSchema)
    });


    const mobileInputHandler = (e) => {
        setValue('mobile', e.target.value.replace(/[^0-9]/g, ''));
    }


    const formSubmitHandler = (data) => {

        const recaptchaToken = captchaRef.current.getValue()

        if (recaptchaToken === '' || recaptchaToken === null) {
            setRecaptchaErr('Re-captcha is required')
            return
        }


        if (
            (
                location?.coordinates?.lattitude === '' ||
                location?.coordinates?.lattitude === null ||
                location?.coordinates?.lattitude === undefined
            ) || (
                location?.coordinates?.longitude === '' ||
                location?.coordinates?.longitude === null ||
                location?.coordinates?.longitude === undefined
            )
        ) {
            toast('Your location is disabled, enable it', { type: 'error' });
            return;
        }

        setRecaptchaErr('')

        if (Object.values(data).every(field => field.length > 0) && isObjEmpty(errors)) {

            setIsLoading(true)
            setFormBtnText('Wait...')

            const postBody = {
                url: apiUrls.onBoarding.step_0.sendOtp,
                payload: {
                    mobile: data.mobile,
                    recaptcha: recaptchaToken,
                    type: 'send',
                    // recaptcha: gToken.value,
                    deviceId: DEVICE_Id
                }
            }

            postRequest(postBody)
                .then(res => {

                    if (res?.success) {
                        setLoginResponse({
                            mobile: data.mobile,
                            otpRefId: res.success.otpRefId
                        })

                        setIsLoading(false);

                        setLoginStep('2');
                    } else if (res?.failed) {
                        toast(res?.failed.message || 'Some error occurred', { type: 'error' })
                        setIsLoading(false)
                    } else {
                        toast('Something went wrong', { type: 'error' })
                        setIsLoading(false)
                    }


                    setFormBtnText('Get started')

                    if (captchaRef?.current)
                        captchaRef.current.reset()

                    // setRefreshReCaptcha(r => !r)

                })
                .catch(error => {
                    toast(error.message || 'Something went wrong', { type: 'error' })
                    setIsLoading(false)
                    setFormBtnText('Get started')

                    if (captchaRef?.current)
                        captchaRef.current.reset()

                    // setRefreshReCaptcha(r => !r);
                })

        }
    }



    return (<>
        <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="content pt-0">
                <h1>Login or Sign Up</h1>
                <p>
                    Enter your BharatATM registerd marchant account mobile number and
                    we will send an verification code via OTP to verify your number.
                </p>

                <Form onSubmit={handleSubmit(formSubmitHandler)}>

                    <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label">
                            Enter your mobile number
                        </label>

                        <Controller
                            id='mobile'
                            name='mobile'
                            control={control}
                            render={({ field }) => <Input
                                type="text"
                                {...register('mobile', { onChange: e => mobileInputHandler(e) })}
                                maxLength="10"
                                className="form-control"
                                invalid={errors.mobile && true} {...field} />}
                        />
                        {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                    </div>

                    <div className='mb-3'>
                        <div className='d-flex justify-content-between mb-2'>
                            {/* <GoogleReCaptcha
                                onVerify={handleVerify}
                                refreshReCaptcha={refreshReCaptcha} /> */}
                            <ReCAPTCHA style={{ transform: 'scale(1.10)', 'WebkitTransform': 'scale(1.10)', transformOrigin: '0 0', 'WebkitTransformOrigin': '0 0' }} sitekey={NOCAPTCHA_SITEKEY} ref={captchaRef} />
                        </div>
                        <span className='text-danger'>{recaptchaErr}</span>
                    </div>

                    <button className="btn px-3" type="submit">
                        <p className="text-center">{formBtnText}</p>
                        <img src="./img/Path.svg" alt="" width="10px" />
                    </button>
                </Form>

                {/* <p className="text-center BharatATM_download mt-3">
                    Sign in with your{" "}
                    <span>
                        <Link to="/login"> BharatATM App</Link>
                    </span>
                </p> */}
            </div>
        </div>
    </>)
}

export default Auth