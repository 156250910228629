export const DEVICE_Id = 'WEB_APP_1.0'
export const NOCAPTCHA_SITEKEY = process.env.REACT_APP_NOCAPTCHA

export const transactionLimits = {
    dmt: {
        min: 1,
        max: 5000
    }
}

export const orderTypes = {
    recharge: 'recharge',
    settlement: 'settlement',
    dmt: "dmt"
}

export const batmWalletTypes = {
    business: 'business',
    earning: 'earning'
}

export const aepsDefaultRoot = {
    cw: 'icici',
    be: 'icici',
    ms: 'icici',
    ap: 'icici',
    eKyc: 'icici',
    bioMetric: 'icici'
}

export const androidAppUrl = 'https://play.google.com/store/apps/details?id=com.app.bharatatm&hl=en_IN&gl=US';

//https APIs
// base_url for production version and 164.54 api is for staging
// const base_url = "https://ba-ws-prod.bharatatm.tech/api/";
//third staging url
// export const base_url = "https://ba-staging.batm.in/api/";
// export const base_url = "https://ba-staging.batm.in/api/";
export const base_url = "https://ba-staging.novostack.net/api/";

// const external_url = "https://ubi.bankmitra.org/Verification/";
export const external_url = "https://falsesight.com/api/v1/eKYC/verification/";
export const otp_path = "check-mobile";
export const verify_otp_path = "distributor/verify-otp";
export const register_details_path = "distributor/signup-step/1";
export const check_pan_path = "distributor/check-pan";
export const verify_pan_external_path = "pan";
export const verify_aadhaar_external_path = "aadhar";
export const aadhaar_otp_on_mobile_external_path = "aadhar";
export const verify_otp_aadhaar_external_path = "aadhar-submit-otp";
export const register_final_path = "distributor/signup-step/2";

export const agent_stat = "distributor/agents";
export const commission_amount = "distributor/commission-amount";

// export const external_token =
//   "ANk67No8j7ksBxSTKCNRKKnVHEkuBIf0s+xb0gjkO4zq+vWu9KtvCLmhUUnmEWep";
export const external_token = "eKYC92226951250630048559FS";
export const cpid = "CP12321311";

//api for merchant dashboard
export const agent_url = "https://ba-staging.novostack.net/api/";
export const AES_KEY = "";
export const money_transfer_url = "https://ba-staging.novostack.net/api/"

// api for profile section 
export const bank_account = "/bank";
export const gst_profile_pic_update = "/profile/update";
export const default_bank_account = "/bank/change/default";
export const add_bank_account = "bank/add";
export const get_fee_commission = "/limit-fees-and-commission";

// bhaesps related to bank
export const bhaeps_base_url = 'https://partner.bharatatm.app/api/';
export const bhaeps_secretkey = 'D653371096E8B1237B381CFD71A0D686';
export const bhaeps_saltkey = '52AA1A509963B25895BBEEB60628B51560756AA0';
export const common_bank_list_path = 'v1/get-bank-list';
export const aeps_base_url = 'https://partner.bharatatm.app/api/';
// export const ifsc_ro

export const txnStatus = {
    0: "pending",
    1: "success",
    2: "failed",
    4: "cancelled"

}

export const txnComments = {
    0: "Transaction Pending",
    1: "Transaction Success",
    2: "Transaction Failed",
    4: "Transaction Cancelled"
}
export const PRODUCTS_ID = {
    mobileRecharge: 1468023594,
    dthRecharge: 1468023595,
    pancard: 1468023596
}


export const MOBILE_CIRCLES_LIST = [
    "Andhra Pradesh Telangana",
    "Assam",
    "Bihar Jharkhand",
    "Chennai",
    "Delhi NCR",
    "Gujarat"
    , "Haryana"
    , "Himachal Pradesh"
    , "Jammu Kashmir"
    , "Karnataka"
    , "Kerala"
    , "Kolkata"
    , "Madhya Pradesh Chhattisgarh"
    , "Maharashtra Goa"
    , "Mumbai"
    , "North East"
    , "Orissa"
    , "Punjab"
    , "Rajasthan"
    , "Tamil Nadu"
    , "UP East"
    , "UP West"
    , "West Bengal"
];

export const genderKeysForPancard = {
    Transgender: "T",
    Female: "F",
    Male: "M"

}
export const genderListForPancard = ["Male", "Female", "Transgender"]
