import { useState, useEffect } from "react";
import "./LoginContainer.scss";
import classNames from "classnames";
import AsideContainer from "../../components/AsideContainer/AsideContainer";
import DownloadApp from "../../common-components/downloadApp";

const LoginContainer = (props) => {

    const [show, setShow] = useState(false);
    // useEffect(() => {
    // Update the document title using the browser API
    // console.log(props, 'stringyfy props');
    // });

    return (
        <div id="SignupContainer">
            <div className="container-fluid">
                <div className="row main-body">
                    <div
                        className={classNames("col-md-4 col-12 slider p-0", {
                            show: show,
                        })}
                    >
                        <AsideContainer> {props.asideContent} </AsideContainer>
                    </div>
                    <div className="col-md-8 col-12 right_section" style={{ padding: '30px 24px' }}>
                        <div className="row d-none d-md-block">
                            <div className="col-12">{props.nav}</div>
                        </div>
                        <div className="row d-md-none d-block">
                            <div className="col-12 d-flex justify-content-between" style={{ marginLeft: '3px' }}>
                                <img src="/img/Group%2010.svg" alt="" width={100 + "px"} />
                                <i
                                    className="fa-solid fa-bars"
                                    onClick={() => {
                                        setShow(!show);
                                    }}
                                ></i>
                            </div>
                        </div>
                        <div className="row content_box">{props.mainContent}</div>
                        <div className="row">
                            <div className="col-12 pb-2 cursor-pointer">
                                {/* <div className="col-12 pb-2" style={{ paddingTop: '100px', cursor: 'pointer' }}> */}
                                <DownloadApp />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginContainer;