import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DownloadApp from "../../common-components/downloadApp";
import { BharatAtmSvgIcon } from "../../icons/svgIcon";

import './freshdesk.css';


const WhatsNew = () => {

    const navigate = useNavigate();

    const [apiResponse, setApiresponse] = useState(null);

    useEffect(() => {
        const ticketUrl = process.env.REACT_APP_TICKET_URL;
        const ticketUser = window.atob(process.env.REACT_APP_TICKET_USER);
        const ticketPass = window.atob(process.env.REACT_APP_TICKET_PASS);


        axios.get(`${ticketUrl}/api/v2/solutions/articles/82000896005`, {
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: ticketUser,
                password: ticketPass
            }
        }).then(response => {
            if (response.status === 200 || response.status === "200") {
                setApiresponse(response.data);
            }
        });


    }, []);



    return (
        <>

            <Fragment>
                <div id="blog_whats_new">
                    <div className="container-fluid">
                        <div className="row">
                            <div className={`col-md-12 col-12 pt-5 pb-4`}>
                                <div className="logo text-center">
                                    <span onClick={() => navigate('/')}><BharatAtmSvgIcon /></span>
                                </div>
                            </div>

                            <div className={`col-md-12 col-12 content`}>

                                <div className="row">

                                    <div className={`col-md-12 col-12 title`}>
                                        <h1 className='mt-4'>{apiResponse?.title}</h1>
                                    </div>

                                    <div className={`col-md-12 col-12 p-5`} dangerouslySetInnerHTML={{ __html: apiResponse?.description }}></div>

                                </div>
                            </div>

                            <div className="col-12 cursor-pointer">
                                <DownloadApp />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        </>
    );
}

export default WhatsNew;