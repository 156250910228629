import { androidAppUrl } from "../../constants/defaultValues";
import { BharatAtmSvgIcon } from "../../icons/svgIcon";
import css from './mobile_view.module.css'

const MobileViewComponent = () => {

    return (
        <>
            <div id="SignupContainer">
                <div className="container-fluid">
                    <div className="row main-body">
                        <div className="col-sm-12 slider p-0">

                            <div className="aside-container left_section">
                                <div className="logo d-flex justify-content-center">
                                    <BharatAtmSvgIcon />
                                </div>

                                <div className={`col-12 pb-2 cursor-pointer mt-5 ${css.alert_container}`}>
                                    <div className="text-center">
                                        <p className="px-5 pt-2 fs-1">Hi, you are connecting with us using a mobile device. Please use our dedicated mobile application for the best experience.</p>
                                        <a href={androidAppUrl} target='_blank'>
                                            <img
                                                src="/img/google-play-badge.png"
                                                alt="Bharat ATM"
                                                width="250px"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="img d-flex justify-content-center align-items-center">
                                    <img src="./img/Create free account Copy.svg" alt="BhartaATM" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileViewComponent