import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signupStep, userToken } from '../services/storageService';

function CheckAuthComponent({ children }) {

    const navigate = useNavigate();
    const location = useLocation();
    const API_TOKEN = userToken();
    const STEP_COMPLETED = signupStep();

    //////////redirect according to registration  step complete
    // ====================
    const redirectBasedOnSteps = () => {
        if (STEP_COMPLETED === 'completed' && location.pathname === '/') {
            navigate('/main-dashboard');
            // toast('Your are  authorized user ,so your are being redirected to main dashboard', { type: 'info' })
        } else if (+STEP_COMPLETED === 3) {
            navigate('/onboarding/address');

            if (location.pathname !== '/') {
                // redirect according link
            } else {
                // navigate('/main-dashboard');
                // toast('Your are  authorized user ,so your are being redirected to main dashboard', { type: 'info' })
            }
        } else if (+STEP_COMPLETED === 2) {
            // navigate('/sign-up/upload-document')
            navigate('/onboarding/user-kyc')

            // toast('Your have already registrated your business location on ' + mobile, { type: 'info' })
        } else if (+STEP_COMPLETED === 1) {
            navigate('/onboarding/personal-info')
            // navigate('/sign-up/business-location')
            // toast('Your have already registrated your business name on ' + mobile, { type: 'info' })
        } else if (location.pathname === '/verify-otp') {
            navigate('/verify-otp')
        }
        if (location.pathname === '/contact-us') {
            navigate('/contact-us')
        }
    }



    // /////////////////////////by lakshay
    // ======== Following redirect function redirect user when he /her will not login=========
    const redirectFunc = () => {
        if (API_TOKEN) {
            redirectBasedOnSteps();
        } else {
            if (location.pathname !== '/verify-otp' &&
                location.pathname !== '/login' &&
                location.pathname !== '/about-us' &&
                location.pathname !== '/contact-us' &&
                location.pathname !== '/terms-and-conditions' &&
                location.pathname !== '/how-referral-works' &&
                location.pathname !== '/privacy-policy' &&
                location.pathname !== '/v1/privacy-policy' &&
                location.pathname !== '/whats-new' &&
                location.pathname !== '/check-old-transaction-history'
            ) {
                navigate('/')
            }

            // if (location.pathname !== '/' &&
            //     location.pathname !== '/verify-otp' &&
            //     location.pathname !== '/login' &&
            //     location.pathname !== '/about-us' &&
            //     location.pathname !== '/contact-us') {
            //     // toast('Your are not authorized user ,so your are being redirected to login page', { type: 'info' })
            // }
            if (location.pathname === '/contact-us') {
                navigate('/contact-us')
            }

        }
    }

    // following function invoked whenever location.pathname will change
    useEffect(() => {
        redirectFunc();
    }, [location.pathname, API_TOKEN, STEP_COMPLETED]);


    // =======================
    // MAINE RETURN STATEMENT
    return children;
}

export default CheckAuthComponent;