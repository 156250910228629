import { createSlice } from "@reduxjs/toolkit";

export const webConfigSlice = createSlice({
    name: 'webConfigSlice',
    initialState: {
        mediaLinks: {},
        txnLimit: null,
        userService: null,
        utiPanResponse: null
    },
    reducers: {
        setMediaLinks(state, action) {
            state.mediaLinks = action.payload.media
        },
        setTxnLimit(state, action) {
            state.txnLimit = action.payload.txnLimit
        },
        setUserService(state, action) {
            state.userService = action.payload.userService
        },
        setUtiPanResponse(state, action) {
            state.utiPanResponse = action.payload
        }
    }
});


export const webConfigActions = webConfigSlice.actions;
export const utiPanResponseHadler = webConfigSlice.actions.setUtiPanResponse;