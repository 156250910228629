import { createSlice } from "@reduxjs/toolkit";

export const mobileRechargeSlice = createSlice({
    name: 'mobileRecharge',
    initialState: {
        invoiceInfo: {}
    },
    reducers: {
        setInvoiceInfo(state, action) {
            state.invoiceInfo = action.payload

            // console.log('invoiceInfo.payload', action.payload)
            // console.log('state.invoiceInfo', state.invoiceInfo)
        }
    }
})


export const mobileRechargeActions = mobileRechargeSlice.actions