import { useNavigate } from "react-router-dom";
import { BharatAtmSvgIcon } from "../../icons/svgIcon";
import "./AsideContainer.scss";

function AsideContainer(props) {
    const navigate = useNavigate();

    return (
        <div className="aside-container left_section">
            <div className="logo d-flex justify-content-center" onClick={() => navigate('/')}>
                {/* <img src="./img/Group 10.svg" alt=""  /> */}
                <BharatAtmSvgIcon />
            </div>
            {props.children}
        </div>
    );
}

export default AsideContainer;
