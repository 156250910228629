import { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import 'react-modern-drawer/dist/index.css'
import './assets/css/index.css'
import LoaderCustom from "./components/custom/LoaderCustom";
import { isMobile, isAndroid, isWinPhone, isTablet } from "react-device-detect";
import MobileViewComponent from "./components/MobileView";
import { fetchUnderMaintenance } from "./services/storageService";
import MaintenanceViewComponent from "./components/MaintenanceView";
import { checkCookie, randomId, setCookie } from "./helpers/cookie";


function renderComponent() {

    const isUnderMaintenance = fetchUnderMaintenance();
    const location = window.location.pathname;



    if (!checkCookie('device_id')) {
        setCookie('device_id', `${Date.now()}0${randomId()}${randomId()}`, 365)
    }


    if (isUnderMaintenance) {
        return <MaintenanceViewComponent message={isUnderMaintenance} />
    }
    else if (
        (isMobile || isAndroid || isWinPhone || isTablet) &&
        (
            location !== '/how-referral-works' &&
            location !== '/terms-and-conditions' &&
            location !== '/privacy-policy' &&
            location !== '/v1/privacy-policy' &&
            location !== '/whats-new' &&
            location !== '/check-old-transaction-history'
        )
    ) {
        return <MobileViewComponent />
    }
    else {
        return (<Provider store={store}>
            <Suspense fallback={<LoaderCustom />}>
                <App />
            </Suspense>
        </Provider>)
    }
}


ReactDOM.render(
    <>
        {renderComponent()}
        {/* <BrowserView> */}
        {/* {!isMobile &&
            <Provider store={store}>
                <Suspense fallback={<LoaderCustom />}>
                    <App />
                </Suspense>
            </Provider>
        } */}
        {/* </BrowserView> */}
        {/* <MobileView> */}
        {/* {isMobile && <MobileViewComponent />} */}
        {/* </MobileView> */}
    </>,
    document.getElementById("root")
);




