import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrls } from "../../../../constants/apiUrls";
import { appUrls } from "../../../../constants/appUrls";
import { DEVICE_Id } from "../../../../constants/defaultValues";
import { greetingMessage } from "../../../../helpers/functions";
import { useGeoLocation } from "../../../../hooks/useGeoLocation";
import { postRequest } from "../../../../services/httpService";
import { setSignupStep, setUserAddress, storeIdToken, storeStepOneInfo, storeUid } from "../../../../services/storageService";
import { fetchUserKycStatus } from "../../../../store/userKycStatusActions";
import { fetchWalletBalance } from "../../../../store/walletBalanceActions";
import { useAuth } from "../../../../utils/auth";

import css from './auth.module.css';

const defaultOtpTimer = 60
const timerObject = { timer: null, timeCounter: 60 }

const SubmitOtp = (props) => {

    const { setIsLoading, loginResponse, setLoginResponse } = props

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useAuth();
    const [location] = useGeoLocation();

    // const [otp, setOtp] = useState(false);
    const [resendOtpBtnEle, setResendOtpBtnEle] = useState()


    const resendOtpHandler = () => {

        if (loginResponse?.otpRefId) {

            timerObject.timeCounter = defaultOtpTimer
            setResendOtpBtnEle(
                <a id="resendOtpBtn" style={{ cursor: 'pointer' }}>Sending...</a>
            )

            setIsLoading(true)

            const postBody = {
                url: apiUrls.onBoarding.step_0.sendOtp,
                payload: {
                    mobile: loginResponse.mobile,
                    type: 'resend',
                    deviceId: DEVICE_Id
                }
            }

            postRequest(postBody)
                .then(res => {

                    if (res?.success) {
                        toast('OTP resent successfully', { type: 'success' })
                        setLoginResponse({
                            mobile: loginResponse.mobile,
                            otpRefId: res.success.otpRefId
                        })
                        setIsLoading(false);
                    } else if (res?.failed) {
                        toast(res?.failed.message || 'Some error occurred', { type: 'error' })
                        setIsLoading(false)
                    } else {
                        toast('Something went wrong', { type: 'error' })
                        setIsLoading(false)
                    }

                })
                .catch(error => {
                    toast(error.message || 'Something went wrong', { type: 'erroe' })
                    setIsLoading(false)
                })


            timerObject.timer = setInterval(() => {
                if (timerObject.timeCounter > 0) {
                    --timerObject.timeCounter
                    setResendOtpBtnEle(
                        <a id="resendOtpBtn" style={{ cursor: 'pointer' }}>{timerObject.timeCounter}</a>
                    )

                } else {
                    clearInterval(timerObject.timer);
                    setResendOtpBtnEle(
                        <a id="resendOtpBtn" style={{ cursor: 'pointer' }} onClick={resendOtpHandler} >Resend OTP</a>
                    )
                    timerObject.timeCounter = defaultOtpTimer
                }
            }, 1000)

        }

    }

    const handleLogin = (e) => {
        e.preventDefault();
        toast.dismiss();

        const otpArr = [];

        Object.values(e.target).map(field => {
            if (field.nodeName === 'INPUT' && field.value !== '') {
                otpArr.push(field.value)
            }
        });


        if (otpArr.length !== 6) {
            toast('Please enter a valid OTP !', { type: 'error' });
            return;
        }


        if (
            (
                location?.coordinates?.lattitude === '' ||
                location?.coordinates?.lattitude === null ||
                location?.coordinates?.lattitude === undefined
            ) || (
                location?.coordinates?.longitude === '' ||
                location?.coordinates?.longitude === null ||
                location?.coordinates?.longitude === undefined
            )
        ) {
            toast('Your location is disabled, enable it', { type: 'error' });
            return;
        }


        const postBody = {
            url: apiUrls.onBoarding.step_0.verifyOtp,
            payload: {
                mobile: loginResponse.mobile,
                otp: otpArr.join(''),
                latitude: location.coordinates.lattitude,
                longitude: location.coordinates.longitude,
                otpRefId: loginResponse.otpRefId,
                deviceId: DEVICE_Id
            }
        }

        setIsLoading(true);
        postRequest(postBody)
            .then(res => {

                if (res?.success) {

                    if (timerObject.timer) {
                        timerObject.timeCounter = defaultOtpTimer;
                        clearInterval(timerObject.timer);
                    }

                    auth.login(res);

                    if (res.success?.user) {

                        storeIdToken(res.success.identifierToken);
                        storeUid(res.success.userId);

                        toast(`${greetingMessage()}, ${res.success.user.firstName}.`, { type: 'success' });
                    } else {
                        toast('OTP verified Successfully', { type: 'success' });
                    }

                    setIsLoading(false);

                    dispatch(fetchUserKycStatus());
                    dispatch(fetchWalletBalance());

                    let aadhaarResponse = res.success?.aadhaarResponse || false;
                    if (aadhaarResponse) {
                        setUserAddress(aadhaarResponse);
                    }

                    if (res.success.step === "1") {
                        if (res.success?.user?.firstName) {
                            storeStepOneInfo(res.success.user);
                        }
                        setSignupStep('1');
                        navigate('/onboarding/personal-info')
                    }
                    else if (res.success.step === "2") {
                        setSignupStep('2');
                        navigate('/onboarding/user-kyc')
                    }
                    else if (res.success.step === "3") {
                        setSignupStep('3');
                        navigate('/onboarding/address')
                    }
                    else if (res.success.step === "4") {
                        setSignupStep('completed');
                        navigate(appUrls.user.dashboard)
                    }
                    else {
                        toast('Invalid step response.', { type: 'error' });
                    }

                } else if (res?.failed) {
                    toast(res?.failed.message || 'Some error occurred', { type: 'error' });
                    setIsLoading(false);
                } else {
                    toast(res?.message ? res.message : res.message, { type: 'error' });
                    setIsLoading(false);
                }

            })
            .catch(error => {
                toast(error.message || 'Something went wrong', { type: 'erroe' })
                setIsLoading(false)
            });

    }


    const otpKeyupHandler = (e) => {
        const values = []
        document.querySelectorAll('.otp_inputs')
            .forEach(ele => {
                if (ele.value !== '' && Number.isInteger(parseInt(ele.value))) {
                    values.push(parseInt(ele.value))
                }
            })

        if (e.key !== undefined && e.key !== null && e.key !== 'Unidentified' && e.key !== 'unidentified') {
            if (e.key === "Delete" || e.key === "Backspace") {
                e.target.value = '';
                // setOtp(true);
                return (e.target.previousSibling) ? e.target.previousSibling.focus() : false
            } else if (e.key === "ArrowRight") {
                return (e.target.nextSibling) ? e.target.nextSibling.focus() : false
            } else if (e.key === "ArrowLeft") {
                return (e.target.previousSibling) ? e.target.previousSibling.focus() : false
            } else if (Number.isInteger(parseInt(e.key))) {
                e.target.value = e.key
                if (values.length === 6) {
                    // setOtp(false);
                    document.getElementById('otpFormBtn').click();
                    // document.getElementById('submitOtpForm').submit();
                } else {
                    // setOtp(true);
                }
                return (e.target.nextSibling) ? e.target.nextSibling.focus() : false
            } else {
                return false
            }
        } else {
            const enteredValue = e.target.value

            if (Number.isInteger(parseInt(enteredValue))) {
                if (values.length === 6) {
                    // setOtp(false);
                    document.getElementById('otpFormBtn').click();
                    // document.getElementById('submitOtpForm').submit();
                } else {
                    // setOtp(true);
                }
                return (e.target.nextSibling) ? e.target.nextSibling.focus() : false
            } else if (enteredValue !== '') {
                e.target.value = ''
                return false
            } else if (enteredValue === '') {
                return (e.target.previousSibling) ? e.target.previousSibling.focus() : false
            }
        }

    }


    useEffect(() => {

        if (timerObject.timer) {
            timerObject.timeCounter = defaultOtpTimer;
            clearInterval(timerObject.timer);
        }

        timerObject.timer = setInterval(() => {
            if (timerObject.timeCounter > 0) {
                --timerObject.timeCounter
                setResendOtpBtnEle(
                    <a id="resendOtpBtn" style={{ cursor: 'pointer' }}>{timerObject.timeCounter}</a>
                )

            } else {
                clearInterval(timerObject.timer);
                setResendOtpBtnEle(
                    <a id="resendOtpBtn" style={{ cursor: 'pointer' }} onClick={resendOtpHandler} >Resend OTP</a>
                )
                timerObject.timeCounter = defaultOtpTimer
            }
        }, 1000)

    }, [])

    return (
        <>
            <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="row content_box justify-content-evenly">
                    <div className="col-7 d-flex justify-content-center align-items-center">
                        <div className="content pt-0" style={{ minWidth: 300 }}>
                            <h1>Verify OTP</h1>
                            <p>
                                One-Time Password (OTP) has been sent to your Mobile Number <strong>{loginResponse.mobile}</strong> .
                            </p>
                            <form id="submitOtpForm" onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <label className="form-label">
                                        <b>Enter OTP</b>
                                    </label>
                                    {/* <OtpInput length={6} onChange={(value) => setOtp(value)} /> */}

                                    <div className="otpContainer">
                                        <input type='tel' onKeyUp={e => otpKeyupHandler(e)} autoFocus maxLength='1' className={`${css.otpInput_custom} otp_inputs`} />
                                        <input type='tel' onKeyUp={e => otpKeyupHandler(e)} maxLength='1' className={`${css.otpInput_custom} otp_inputs`} />
                                        <input type='tel' onKeyUp={e => otpKeyupHandler(e)} maxLength='1' className={`${css.otpInput_custom} otp_inputs`} />
                                        <input type='tel' onKeyUp={e => otpKeyupHandler(e)} maxLength='1' className={`${css.otpInput_custom} otp_inputs`} />
                                        <input type='tel' onKeyUp={e => otpKeyupHandler(e)} maxLength='1' className={`${css.otpInput_custom} otp_inputs`} />
                                        <input type='tel' onKeyUp={e => otpKeyupHandler(e)} maxLength='1' className={`${css.otpInput_custom} otp_inputs`} />
                                    </div>
                                </div>

                                <div className="resend_otp">
                                    <p>
                                        Did not receive? {resendOtpBtnEle}
                                    </p>
                                </div>
                                <button type="submit" id="otpFormBtn" className="btn px-3">
                                    <p className="text-center">Proceed</p>
                                    <img src="./img/Path.svg" alt="" width="10px" />
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SubmitOtp