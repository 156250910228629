import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
    name: 'commonSlice',
    initialState: {
        contactUs: null
    },
    reducers: {
        setContactUs(state, action) {
            state.contactUs = action.payload
        }
    }
})


export const commonActions = commonSlice.actions