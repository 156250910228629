import { createSlice } from "@reduxjs/toolkit";

export const userKycStatusSlice = createSlice({
    name: 'userKycStatus',
    initialState: {
        userKyc: null,
        userInfo: null,
        userBizInfo: null,
        quickLinks: null,
        referralUrl: null
    },
    reducers: {
        setUserKyc(state, action) {
            state.userKyc = action.payload.userKyc
        },
        setUserInfo(state, action) {
            state.userInfo = action.payload.user
        },
        setUserBizInfo(state, action) {
            state.userBizInfo = action.payload.bizInfo;
        },
        setQuickLinks(state, action) {
            state.quickLinks = action.payload.quickLinks
        },
        setReferralUrl(state, action) {
            state.referralUrl = action.payload
        }
    }
})

export const userKycActions = userKycStatusSlice.actions;