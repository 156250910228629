import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { TbRefreshAlert } from "react-icons/tb";
import { fetchIdToken, fetchUid, userToken } from "../services/storageService";
import { generateRequestHash } from "./encdec";


export const getDate = (date = null) => {
    let dateObj
    if (date) {
        dateObj = new Date(date)
    } else {
        dateObj = new Date()
    }

    const dd = String(dateObj.getDate()).padStart(2, '0')
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0')
    const yyyy = dateObj.getFullYear()
    return `${yyyy}-${mm}-${dd}`
}


export const hashHandler = (urlEndPoint) => {

    urlEndPoint = urlEndPoint || ''

    let key = '';
    let uid = '';
    let iv = '';

    if (userToken() && fetchIdToken() && fetchUid()) {
        key = fetchIdToken();
        uid = fetchUid().replaceAll('"', '').substr(-4);
        iv = `${process.env.REACT_APP_IV_II}${uid}`;
    } else {
        key = process.env.REACT_APP_ENC_KEY;
        iv = process.env.REACT_APP_IV;
    }

    const signStr = `${process.env.REACT_APP_SIGN_I}${urlEndPoint}${process.env.REACT_APP_SIGN_II}${new Date().getTime()}`;

    const hashedSign = generateRequestHash(signStr, window.atob(key), iv);

    // console.log('key iv', window.atob(key), iv, urlEndPoint);
    // console.log('hashedSign', hashedSign, signStr);

    return hashedSign;
}



export const txnStatusIcon = (status) => {

    let ret = '';

    switch (status) {

        case 'success':
        case 's':
            ret = (<span ><FiCheckCircle size='40' style={{ width: 'initial', color: "#45B07C" }} /></span>);
            break;

        case 'pending':
        case 'p':
        case 'in_progress':
            ret = (<span className='txt-warning'><TbRefreshAlert size='40' style={{ width: 'initial' }} /></span>);
            break;

        case 'failed':
        case 'f':
        case 'FAILURE':
        case 'cancelled':
        case 'CANCELLED':
        case 'Cancelled':
            ret = (<span className='text-danger'><FiXCircle size='40' style={{ width: 'initial' }} /></span>);
            break;


        default:
            ret = status;

    }

    return ret;
}


export const popRdServiceStatus = (data) => {
    if (data) {
        const filter = data.filter(item => {
            if (item.name.toLowerCase() === "resp") {
                return true;
            }
        });

        return filter;
    }
}



export const greetingMessage = (user) => {
    const dateObj = new Date();

    const h = dateObj.getHours();

    if (h >= 0 && h < 12) {
        return 'Good morning';
    }

    if (h >= 12 && h < 18) {
        return 'Good afternoon';
    }

    if (h >= 18 && h <= 24) {
        return 'Good evening';
    }

    return 'Have a good day';

}


export const amountParsing = (amt) => {
    if (amt) {
        return parseFloat(amt).toFixed(2)
    }

    return amt
}