import { decryptor, encryptor } from "../helpers/encdec";


export const storeTempData = (key, val) => {
    localStorage.setItem(key, encryptor(val));
}

export const fetchTempData = (key) => {
    try {
        const d = localStorage.getItem(key);
        if (d) {
            return decryptor(d);
        }
        return '';
    }
    catch (error) {
        return '';
    }
}


export const clearTempData = (key) => {
    localStorage.removeItem(key);
}

export const fetchUnderMaintenance = () => {
    try {
        const d = localStorage.getItem("maintenance");
        if (d) {
            return decryptor(d);
        }
        return '';
    }
    catch (error) {
        return '';
    }
}

export const storeUnderMaintenance = (data) => {
    localStorage.setItem("maintenance", encryptor(data));
}

export const clearUnderMaintenance = () => {
    localStorage.removeItem('maintenance');
}


export const userToken = () => {
    try {
        const d = localStorage.getItem("api_token");
        if (d) {
            return decryptor(d);
        }

        return '';
    } catch (error) {
        localStorage.clear();
        return '';
    }
}

export const setUserToken = (token) => {
    localStorage.setItem("api_token", encryptor(token));
}


export const signupStep = () => {
    try {
        const d = localStorage.getItem("step_completed");
        if (d) {
            return decryptor(d);
        }
        return '';
    }
    catch (error) {
        return '';
    }
}

export const storeStepOneInfo = (step) => {
    step = encryptor(step);
    localStorage.setItem("step_one_info", step);
}


export const fetchStepOneInfo = () => {
    try {
        const d = localStorage.getItem("step_one_info");
        if (d) {
            return decryptor(d);
        }
        return '';
    }
    catch (error) {
        return '';
    }
}

export const setSignupStep = (step) => {
    step = encryptor(step);
    localStorage.setItem("step_completed", step);
}


export const storeUserBanks = (data) => {
    localStorage.setItem("userBanks", encryptor(JSON.stringify(data)));
}

export const fetchUserBanks = () => {
    try {
        const d = localStorage.getItem('userBanks');
        if (d) {
            return JSON.parse(decryptor(d));
        }
        return '';
    }
    catch (error) {
        return '';
    }
    // return localStorage.getItem('userBanks') && JSON.parse(localStorage.getItem('userBanks'))
}


export const userAddress = () => {
    try {
        const d = localStorage.getItem('userAddress');
        if (d) {
            return JSON.parse(decryptor(d));
        }
        return '';
    }
    catch (error) {
        return '';
    }
    // return localStorage.getItem("userAddress") && JSON.parse(localStorage.getItem("userAddress"));
}

export const setUserAddress = (userData) => {
    localStorage.setItem("userAddress", encryptor(JSON.stringify(userData)));
}


export const getUserInfo = () => {
    try {
        const d = localStorage.getItem('userInfo');
        if (d) {
            return JSON.parse(decryptor(d));
        }
        return '';
    }
    catch (error) {
        return '';
    }
    // return localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"));
}


export const storeIdToken = (data) => {
    localStorage.setItem("id_token", encryptor(JSON.stringify(data)));
}

export const fetchIdToken = () => {
    try {
        const d = localStorage.getItem('id_token');
        if (d) {
            return JSON.parse(decryptor(d));
        }
        return '';
    }
    catch (error) {
        return '';
    }
}

export const storeUid = (data) => {
    localStorage.setItem('uid_token', encryptor(JSON.stringify(data)));
}

export const fetchUid = () => {
    try {
        const d = localStorage.getItem('uid_token');
        if (d) {
            return decryptor(d);
        }
        return '';
    }
    catch (error) {
        return '';
    }
}


export const setUserInfo = (userData) => {
    localStorage.setItem("userInfo", encryptor(JSON.stringify(userData)));
}

export const userKyc = () => {
    return localStorage.getItem("userKyc") && JSON.parse(localStorage.getItem("userKyc"));
}
export const setUserKyc = (userData) => {
    localStorage.setItem("userKyc", JSON.stringify(userData));
}

export const getUserBalance = () => {
    return localStorage.getItem("userBalance") && JSON.parse(localStorage.getItem("userBalance"));
}
export const setUserBalance = (userData) => {
    localStorage.setItem("userBalance", JSON.stringify(userData));
}

export const userService = () => {
    return localStorage.getItem("userService") && JSON.parse(localStorage.getItem("userService"));
}
export const setUserService = (userData) => {
    localStorage.setItem("userService", JSON.stringify(userData));
}


export const userMobile = () => {
    return localStorage.getItem("mobile");
}

export const setUserMobile = (mobile) => {
    localStorage.setItem("mobile", mobile);
}


export const rechargeData = () => {
    return localStorage.getItem("recharge_data");
}

export const setRechargeData = (data) => {
    localStorage.setItem("recharge_data", JSON.stringify(data));
}


export const clearStorage = () => localStorage.clear()


export const fetchEkycSendOtpResponse = () => {
    return localStorage.getItem("ekycSendOtpResponse") && JSON.parse(localStorage.getItem("userAddress"));
}

export const storeEkycSendOtpResponse = (data) => {
    localStorage.setItem("ekycSendOtpResponse", JSON.stringify(data));
}


export const logoutExplicit = () => {
    localStorage.clear();
}