import { apiUrls } from "../constants/apiUrls";
import { getRequest } from "../services/httpService";
import { walletActions } from "./walletBalanceSlice";

export const fetchWalletBalance = () => {

    return async (dispatch) => {

        getRequest({ url: apiUrls.dashboard.balance })
            .then(response => {
                if (response?.success) {
                    dispatch(
                        walletActions.setWalletBalance(response.success.userBalance)
                    )
                } else {
                    dispatch(
                        walletActions.setWalletBalance([])
                    )
                }
            })
            .catch(() => {
                dispatch(
                    walletActions.setWalletBalance([])
                )
            })
    }
}