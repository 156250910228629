import { lazy, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./utils/auth";
import { useDispatch } from "react-redux";

import { fetchWalletBalance } from "./store/walletBalanceActions";
import { userToken } from './services/storageService';
import { fetchUserKycStatus } from './store/userKycStatusActions';

import GetStarted from "./Auth/Login/GetStarted/GetStarted";
import CheckAuthComponent from "./Auth/CheckAuthComponent";
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import NotFountPage from "./pages/not-found/not-found.page";

import "./App.scss";
import 'react-toastify/dist/ReactToastify.css';
import { appUrls } from "./constants/appUrls";

import WhatsNew from "./pages/Freshdesk/WhatsNew";

import { useGeoLocation } from "./hooks/useGeoLocation";

const PancardHistory = lazy(() => import("./components/OrderHistory/PancardHistory"));
const UtiPanService = lazy(() => import("./components/PanCardService/Uti/UtiPanService"));
const Referral = lazy(() => import("./components/Referral"));
const HowReferralWorks = lazy(() => import("./pages/HowReferralWorks"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

const UserOnboardingFormLayout = lazy(() => import("./components/UserOnboarding"));
const Step1 = lazy(() => import("./components/UserOnboarding/Step1"));
const Step2 = lazy(() => import("./components/UserOnboarding/Step2"));
const Step3 = lazy(() => import("./components/UserOnboarding/Step3"));

const UserOnboardingFinal = lazy(() => import("./components/UserOnboardingFinal"));
const UpdateShopInfo = lazy(() => import("./components/UserOnboardingFinal/UpdateShopInfo"));
const KycDoc = lazy(() => import("./components/UserOnboardingFinal/KycDoc"));
const UpdateVideoKyc = lazy(() => import("./components/UserOnboardingFinal/UpdateVideoKyc"));

const AepsOnboardingFormLayout = lazy(() => import("./components/AepsOnboarding"));
const Onboarding = lazy(() => import("./components/AepsOnboarding/Onboarding"));

const Ekyc = lazy(() => import("./components/AepsOnboarding/Ekyc"));

const MainDashboard = lazy(() => import("./components/MainDashboard/MainDashboard"));
const Profile = lazy(() => import("./components/Profile/Profile"));
const AddBankAccountForm = lazy(() => import("./components/Profile/AddBankAccountForm"));

const BillPayments = lazy(() => import("./components/BillPayments/BillPayments"));
const MobileRechargeBillPayment = lazy(() => import("./components/BillPayments/MobileRecharge/MobileRechargeBillPayment"));
const DthBillPayment = lazy(() => import("./components/BillPayments/DTH/DthBillPayment"));

const ForAuthUser = lazy(() => import('./components/CommingSoon/ForAuthUser'));
// const WithSideNav = lazy(() => import('./components/CommingSoon/WithSideNav'));

const AepsTxns = lazy(() => import('./components/Aeps/AepsTxns'));
const WithdrawCash = lazy(() => import("./components/WithdrawCash/WithdrawCash"));
const GetStatement = lazy(() => import("./components/GetStatement"));
const CheckBalance = lazy(() => import("./components/CheckBalance/CheckBalance"));
const AdhaarPay = lazy(() => import('./components/AadhaarPay/AdhaarPay'));
const RechargeHistory = lazy(() => import('./components/OrderHistory/RechargeHistory'));
const FeeAndComission = lazy(() => import("./components/FeeAndComission/FeeAndComission"));
const MoneyTransferHistory = lazy(() => import("./components/OrderHistory/MoneyTransferHistory"));

const MoneyTransferLayout = lazy(() => import("./components/MoneyTransfer/MoneyTransferLayout"));
const MoneyTransferSendMoney = lazy(() => import("./components/MoneyTransfer/MoneyTransferSendMoney"));
const Offers = lazy(() => import("./components/Offers/Offers"));
const Insurance = lazy(() => import("./components/Insurance"));

const AepsIndex = lazy(() => import("./components/OrderHistory/AepsIndex"));
const MatmIndex = lazy(() => import("./components/OrderHistory/MatmIndex"));
const WalletIndex = lazy(() => import("./components/OrderHistory/WalletIndex"));
const SettlementIndex = lazy(() => import("./components/Profile/SettlementIndex"));
const OldTransactionHistory = lazy(() => import("./pages/Freshdesk/OldTransactionHistory"));


function App() {

    const dispatch = useDispatch();
    const [location] = useGeoLocation();

    useEffect(() => {
        if (userToken()) {
            dispatch(fetchUserKycStatus());
            dispatch(fetchWalletBalance());
        }
    }, [dispatch])


    useEffect(() => {
        const pathName = window.location.pathname;

        if ((
            pathName !== '/how-referral-works' &&
            pathName !== '/terms-and-conditions' &&
            pathName !== '/privacy-policy' &&
            pathName !== '/v1/privacy-policy' &&
            pathName !== '/whats-new'
        )) {
            (location.error?.status === 201 || location.error?.status === 0) && toast('Please enable your location.', { type: "error" });
        }
    }, [location]);


    return (
        <AuthProvider>
            <ToastContainer position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />

            <BrowserRouter>
                <CheckAuthComponent>
                    <Routes>
                        <Route path="/" exact element={<GetStarted />} />
                        <Route path="/terms-and-conditions" element={<TermsConditions />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/how-referral-works" element={<HowReferralWorks />} />
                        <Route path="/v1/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/whats-new" element={<WhatsNew />} />
                        <Route path="/check-old-transaction-history" element={<OldTransactionHistory />} />
                        <Route path="/referral" element={<Referral />} />
                        {/* <Route path="/login" element={<LoginPage />} /> */}
                        {/* <Route path='/order-history' element={<OrderHistory />} /> */}

                        {/* <Route path="/fees-&-commission" element={<WithSideNav />} /> */}
                        <Route path="/fees-&-commission" element={<FeeAndComission />} />
                        {/* <Route path="/money-transfer/" element={<MoneyTransferLayoutMain />} > */}
                        {/* <Route path="history" element={<TransferHistory />} /> */}
                        {/* <Route path="contact-details" element={<ContactDetailLayout />} /> */}
                        {/* </Route> */}



                        <Route path="/onboarding/" element={<UserOnboardingFormLayout />}>
                            <Route path="personal-info" element={<Step1 />} />
                            <Route path="user-kyc" element={<Step2 />} />
                            <Route path="address" element={<Step3 />} />
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>

                        <Route path="/complete/" element={<UserOnboardingFinal />}>
                            <Route path="shop-info" element={<UpdateShopInfo />} />
                            <Route path="kyc-docs" element={<KycDoc />} />
                            <Route path="video-kyc" element={<UpdateVideoKyc />} />
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>

                        <Route path="/aeps/" element={<AepsOnboardingFormLayout />}>
                            <Route path="on-boarding" element={<Onboarding />} />
                            <Route path="e-kyc" element={<Ekyc />} />
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>

                        <Route
                            path="/main-dashboard"
                            element={<MainDashboard />} />

                        <Route
                            path="/insurance"
                            element={<Insurance />} />
                        {/* <Route
                            path="/distributor-dashboard"
                            element={<DistributorDashboard />}
                        /> */}


                        <Route path={appUrls.user.recharge.base} element={<BillPayments />}>
                            <Route path="dth-recharge" element={<DthBillPayment />} />
                            <Route path="mobile-recharge" element={<MobileRechargeBillPayment />} />
                            <Route path="electricity" element={<ForAuthUser />} />
                            <Route path="comming-soon" element={<ForAuthUser />} />
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>

                        <Route path={appUrls.user.bbps.base} element={<BillPayments />}>
                            <Route path="electricity" element={<ForAuthUser />} />
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>

                        <Route path={appUrls.user.aepsTxn.base} element={<AepsTxns />}>
                            <Route path="cash-withdrawal" element={<WithdrawCash />} />
                            <Route path="aadhaar-pay" element={<AdhaarPay />} />
                            <Route path="check-balance" element={<CheckBalance />} />
                            <Route path="get-statement" element={<GetStatement />} />
                            {/* <Route path="get-statement/print" element={<GetStatementPrint />} /> */}
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>


                        <Route path={appUrls.user.dmt.base} element={<MoneyTransferLayout />}>
                            <Route path="send" element={<MoneyTransferSendMoney />} />
                            <Route path="" element={<Navigate to="/main-dashboard" />} replace />
                        </Route>

                        <Route path={'/pan/uti'} element={<UtiPanService />} />

                        {/* <Route path="/phone-number" element={<PhoneNumber />} /> */}
                        {/* <Route path="/validate-otp" element={<ValidateOtp />} /> */}
                        {/* <Route path="/your-name" element={<YourName />} /> */}
                        {/* <Route path="/pan-number" element={<PanNumber />} /> */}
                        {/* <Route path="/aadhaar-card" element={<AadhaarCard />} /> */}
                        {/* <Route path="/verify-aadhaar-otp" element={<VerifyAadhaarOtp />} /> */}
                        {/* <Route path="/thank-you" element={<ThankYou />} /> */}
                        <Route path="/profile" element={<Profile />} />
                        {/* <Route path="/offers" element={<WithSideNav />} /> */}
                        <Route path="/offers" element={<Offers />} />
                        <Route path="/account-form" element={<AddBankAccountForm />} />

                        {/* <Route path='/order-history' element={<OrderHistory />} /> */}
                        <Route path='/recharge-history' element={<RechargeHistory />} />
                        <Route path='/pancard-history' element={<PancardHistory />} />
                        <Route path='/money-transfer-history' element={<MoneyTransferHistory />} />
                        <Route path='/aeps-history' element={<AepsIndex />} />
                        <Route path='/matm-history' element={<MatmIndex />} />
                        <Route path='/wallet-statement' element={<WalletIndex />} />
                        <Route path='/settlement' element={<SettlementIndex />} />

                        {/* <Route path="/main-dashboard" element={<MainDashboard />} /> */}

                        {/* <Route path="/settlement" element={<MyBussiness />} /> */}
                        {/* <Route path="/earning-statement" element={<MyEarning />} /> */}

                        {/* <Route
                            path="/bill-payments-consumer-details"
                            element={<ConsumerDetails />}
                        /> */}
                        {/* <Route
                            path="/distributor-dashboard"
                            element={<DistributorDashboard />}
                        /> */}

                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/contact-us" element={<ContactUs />} />

                        <Route path="*" element={<NotFountPage />} />
                    </Routes>
                </CheckAuthComponent>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
