import { createContext, useState, useContext } from "react";
import { apiUrls } from "../constants/apiUrls";
import { batmWalletTypes } from "../constants/defaultValues";
import { getRequest } from "../services/httpService";
import { setUserToken } from "../services/storageService";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const [tpinModalState, setTpinModalState] = useState(false);
    const [settleWalletTypeState, setSettleWalletTypeState] = useState(batmWalletTypes.business);

    const login = (res) => {
        setUserToken(res.success.apiToken);
        setUser(res);
    }


    const logout = () => {
        getRequest({ url: apiUrls.logout }).then(() => {
            localStorage.clear();
            setUser(null);
            window.location.replace('/');
        });
    }


    return (
        <AuthContext.Provider value={{
            user,
            login,
            logout,
            tpinModalState,
            settleWalletTypeState,
            setSettleWalletTypeState,
            setTpinModalState
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}