import { useEffect } from "react";
import { FiFacebook, FiInstagram, FiMail, FiPhoneCall, FiTwitter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import LoginContainer from "../../Auth/Login/LoginContainer";
import { apiUrls } from "../../constants/apiUrls";
import { getRequest } from "../../services/httpService";
import { userToken } from "../../services/storageService";
import { commonActions } from "../../store/commonSlice";

import styles from './index.module.css';

const ContactUs = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageState = useSelector(state => state.common.contactUs);

    // const [pageState, setPageState] = useState();

    useEffect(() => {
        getRequest({ url: apiUrls.commom.support })
            .then(res => {
                if (res?.success) {
                    // setPageState(res.success);
                    dispatch(commonActions.setContactUs(res.success))
                }
            })
            .catch(error => {
                console.log('Contact Us', error);
            });
    }, [pageState]);

    return (<>

        <LoginContainer
            nav={<>
                <h5 className="text-end">
                    {userToken() && <span className="text-end f16 me-3" onClick={() => navigate('/main-dashboard')} style={{ cursor: 'pointer' }}>Home</span>}
                    {userToken() === '' && <span className="text-end f16 me-3" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Get Started</span>}
                    <span className="text-end f16 ms-3 me-3" onClick={() => navigate('/about-us')} style={{ cursor: 'pointer' }}>About Us</span>
                    <span className="text-end f16 ms-3" onClick={() => navigate('/contact-us')} style={{ cursor: 'pointer' }}>Contact Us</span>
                </h5>
            </>
            }
            asideContent={
                <div className="img d-flex justify-content-center align-items-center">
                    <img src="./img/Create free account Copy.svg" alt="" />
                </div>
            }
            mainContent={
                <>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className={styles.body}>
                                <h1 className='mt-4'>Contact US</h1>

                                <p className={`${styles.para} mt-3 mb-3`}>Do you need help? Reach out to us!</p>

                                <Row>
                                    <Col sm="4"></Col>
                                    <Col sm="4">

                                        <table className="table table-borderless mt-5 mb-5">
                                            <tbody>
                                                <tr>
                                                    <th className="fit"><FiMail /></th>
                                                    <th className="fit">:</th>
                                                    <td className="text-start">{pageState?.email}</td>
                                                </tr>
                                                <tr>
                                                    <th><FiPhoneCall /></th>
                                                    <th className="fit">:</th>
                                                    <td className="text-start">{pageState?.mobile}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="table table-borderless mt-5">
                                            <tbody>
                                                <tr>
                                                    {pageState?.facebook &&
                                                        <th className="fit">
                                                            <a href={pageState?.facebook}
                                                                target="_blank" rel="noreferrer" alt="Facebook">
                                                                <FiFacebook size={24} color='#4267B2' />
                                                            </a>
                                                        </th>
                                                    }

                                                    {pageState?.instagram &&
                                                        <th className="fit">
                                                            <a href={pageState?.instagram}
                                                                target="_blank" rel="noreferrer" alt="instagram">
                                                                <FiInstagram size={24} color="#fb3958" />
                                                            </a>
                                                        </th>
                                                    }

                                                    {pageState?.twitter &&
                                                        <th className="fit">
                                                            <a href={pageState?.twitter}
                                                                target="_blank" rel="noreferrer" alt="twitter"
                                                                className={styles.instaColor}
                                                            >
                                                                <FiTwitter size={24} color='#1DA1F2' />
                                                            </a>
                                                        </th>
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>

                                    </Col>
                                    <Col sm="4"></Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    </>);
}

export default ContactUs;