import { apiUrls } from "../../constants/apiUrls";
import { BharatAtmSvgIcon } from "../../icons/svgIcon";
import { getRequest } from "../../services/httpService";
import { clearUnderMaintenance } from "../../services/storageService";
import css from './maintenance_view.module.css'

const MaintenanceViewComponent = ({ message }) => {

    const refreshBtnHandler = () => {

        getRequest({ url: apiUrls.dashboard.init })
            .then(res => {
                if (res?.success) {
                    clearUnderMaintenance();
                    window.location.replace('/');
                }
            })
            .catch(err => {
                console.log('MaintenanceView', err)
            })
    }

    return (
        <>
            <div id="SignupContainer">
                <div className="container-fluid">
                    <div className={`row main-body ${css.main_body}`}>
                        <div className="col-md-12 col-12 slider p-0">

                            <div className="aside-container">
                                <div className="logo d-flex justify-content-center">
                                    <BharatAtmSvgIcon />
                                </div>

                                <div className={`col-12 pb-2 cursor-pointer mt-5 ${css.alert_container}`}>
                                    <div className="text-center">
                                        <p className="px-5 pt-2 fs-1">{message}</p>
                                        <div className={css.maintenance_img_div}>
                                            {/* <a href={androidAppUrl} target='_blank'> */}
                                            <img
                                                src="/img/maintenance.gif"
                                                alt="Bharat ATM"
                                                width="100%"
                                            />
                                        </div>
                                        {/* </a> */}
                                    </div>

                                    <div className="text-center">
                                        <button className="btn btn-outline-secondary" onClick={refreshBtnHandler}>Refresh</button>
                                    </div>
                                </div>

                                {/* <div className="img d-flex justify-content-center align-items-center"> */}
                                {/* <img src="./img/Create free account Copy.svg" alt="BhartaATM" /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaintenanceViewComponent