import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import classNames from "classnames";
import DownloadApp from '../../common-components/downloadApp';
import { BharatAtmSvgIcon } from '../../icons/svgIcon';

const { body } = styles;

const NotFountPage = () => {

    const navigate = useNavigate();

    return (
        <Fragment>
            <div id="SignupContainer">
                <div className="container-fluid">
                    <div className="row main-body">
                        <div className={classNames("col-md-12 col-12 slider")}>
                            <div className="pt-5 pb-5 logo text-center" onClick={() => navigate('/')}>
                                <BharatAtmSvgIcon />
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className={body}>
                                    <h1 className='mt-4'>404</h1>
                                    <h2 className='mt-4'>Oops, Page not found!</h2>
                                    <div className='mt-4'>
                                        <Link to={'/'} >
                                            Go back to home
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 cursor-pointer">
                                    <DownloadApp />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NotFountPage;