import { createSlice } from "@reduxjs/toolkit"


export const dmtUserInfoSlice = createSlice({
    name: 'moneyTransfer',
    initialState: {
        remitterInfo: null
    },
    reducers: {
        setRemitterInfo(state, action) {
            // console.log('setRemitterInfo', action)
            state.remitterInfo = action.payload
        },
        unsetRemitterInfo(state, action) {
            // console.log('unsetRemitterInfo', action.payload)
            state.remitterInfo = action.payload
        }
    }
})


export const dmtUserInfoActions = dmtUserInfoSlice.actions