import React from 'react'
import './index.css'
// import { BharatAtmSvgIcon } from '../../icons/svgIcon';


const LoaderCustom = ({ className, text }) => {
    return (
        <div className={`${className} custom-loader`}>
            {/* <div><BharatAtmSvgIcon /></div> */}
            <span></span>
            {text && <div>{text}</div>}
        </div>
    )
}

export default LoaderCustom;