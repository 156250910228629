import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import LoginContainer from "../../Auth/Login/LoginContainer";
import { apiUrls } from "../../constants/apiUrls";
import { getRequest } from "../../services/httpService";
import { userToken } from "../../services/storageService";

import styles from './index.module.css';

const AboutUs = () => {

    const navigate = useNavigate();

    const [pageState, setPageState] = useState();

    useEffect(() => {

        getRequest({ url: apiUrls.commom.support })
            .then(res => {
                if (res?.success) {
                    setPageState(res.success);
                }
            })
            .catch(error => {
                console.log('Contact Us', error);
            });
    }, []);

    return (<>
        <LoginContainer
            nav={<>
                <h5 className="text-end">
                    {userToken() && <span className="text-end f16 me-3" onClick={() => navigate('/main-dashboard')} style={{ cursor: 'pointer' }}>Home</span>}
                    {userToken() === '' && <span className="text-end f16 me-3" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Get Started</span>}
                    <span className="text-end f16 ms-3 me-3" onClick={() => navigate('/about-us')} style={{ cursor: 'pointer' }}>About Us</span>
                    <span className="text-end f16 ms-3" onClick={() => navigate('/contact-us')} style={{ cursor: 'pointer' }}>Contact Us</span>
                </h5>
            </>
            }
            asideContent={
                <div className="img d-flex justify-content-center align-items-center">
                    <img src="./img/Create free account Copy.svg" alt="" />
                </div>
            }
            mainContent={
                <>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className={styles.body}>
                                <h1 className='mt-4'>About US</h1>

                                <Row>
                                    <Col sm="2"></Col>
                                    <Col sm="8">
                                        <p className={styles.para}>
                                            {pageState?.aboutUs || "Join India's largest Assisted Banking Network! Download app to register as Merchant"}
                                        </p>
                                    </Col>
                                    <Col sm="2"></Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    </>);
}

export default AboutUs;