import { apiUrls } from "../constants/apiUrls"
import { getRequest } from "../services/httpService"
import { userKycActions } from "./userKycStatusSlice"
import { webConfigActions } from "./webConfigSlice"

export const fetchUserKycStatus = () => {
    return async (dispatch) => {

        getRequest({
            url: apiUrls.dashboard.init,
            payload: {
                area: "web"
            }
        }).then(response => {

            if (response?.success) {

                dispatch(
                    userKycActions.setUserKyc({ userKyc: response.success.userKyc })
                );
                dispatch(
                    userKycActions.setUserInfo({ user: response.success.user })
                );
                dispatch(
                    userKycActions.setUserBizInfo({ bizInfo: response.success.userBusinessInfo })
                );
                dispatch(
                    userKycActions.setQuickLinks({ quickLinks: response.success.quickLinks })
                );
                dispatch(
                    userKycActions.setReferralUrl(response.success.referralUrl)
                );
                dispatch(
                    webConfigActions.setMediaLinks({ media: response.success.media })
                );
                dispatch(
                    webConfigActions.setTxnLimit({ txnLimit: response?.success?.txnLimit })
                );
                dispatch(
                    webConfigActions.setUserService({ userService: response?.success?.userConfigService })
                );
            } else {
                dispatch(
                    userKycActions.setUserKyc({ userKyc: null })
                );
                dispatch(
                    userKycActions.setUserInfo({ user: null })
                );
                dispatch(
                    userKycActions.setUserBizInfo({ bizInfo: null })
                );
                dispatch(
                    userKycActions.setQuickLinks({ quickLinks: null })
                );
                dispatch(
                    userKycActions.setReferralUrl(null)
                );
                dispatch(
                    webConfigActions.setTxnLimit({ txnLimit: null })
                );
                dispatch(
                    webConfigActions.setUserService({ userService: null })
                );
            }
        }).catch(() => {
            dispatch(
                userKycActions.setUserKyc({ userKyc: null })
            );
            dispatch(
                userKycActions.setUserInfo({ user: null })
            );
            dispatch(
                userKycActions.setUserBizInfo({ bizInfo: null })
            );
            dispatch(
                userKycActions.setQuickLinks({ quickLinks: null })
            );
            dispatch(
                userKycActions.setReferralUrl(null)
            );
            dispatch(
                webConfigActions.setTxnLimit({ txnLimit: null })
            );
            dispatch(
                webConfigActions.setUserService({ userService: null })
            );
        })
    }
}