import { androidAppUrl } from "../constants/defaultValues";

export default function DownloadApp() {
    return (
        <p className="text-center footer">
            Join <span>India's largest Assisted Banking Network!</span>{" "}
            Download app to register as Merchant.
            <a href={androidAppUrl} target='_blank'>
                <img
                    src="/img/google-play-badge.png"
                    alt=""
                    width="150px"
                />
            </a>
        </p>
    )
}