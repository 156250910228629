import { configureStore } from "@reduxjs/toolkit"
import { commonSlice } from "./commonSlice"
import { dmtUserInfoSlice } from "./dmtUserInfoSlice"
import { mobileRechargeSlice } from "./mobileRechargeSlice"
import { userKycStatusSlice } from "./userKycStatusSlice"
import { walletBalanceSlice } from "./walletBalanceSlice"
import { webConfigSlice } from "./webConfigSlice"


const store = configureStore({
    reducer: {
        wallet: walletBalanceSlice.reducer,
        userKyc: userKycStatusSlice.reducer,
        dmtInfo: dmtUserInfoSlice.reducer,
        recharge: mobileRechargeSlice.reducer,
        webConfig: webConfigSlice.reducer,
        common: commonSlice.reducer
    }
})

export default store