export const appUrls = {
    user: {
        dashboard: '/main-dashboard',
        recharge: {
            name: 'recharge',
            base: '/recharge/',
            dashboard: '/recharge/mobile-recharge',
            mobile: '/recharge/mobile-recharge',
            dth: '/recharge/dth-recharge',
            invoice: '/recharge/mobile-recharge/invoice'
        },
        bbps: {
            base: '/bbps/',
            electricity: '/bbps/electricity'
        },
        aeps: {
            base: '/aeps/',
            onboarding: '/aeps/on-boarding',
            eKyc: '/aeps/e-kyc'
        },
        pan: {
            uti: {
                dashboard: '/pan/uti'
            }
        },
        dmt: {
            base: '/money-transfer/',
            sendMoney: '/money-transfer/send',
            onboarding: '/money-transfer/on-boarding',
            eKyc: '/aeps/e-kyc'
        },
        insurance: {
            base: '/insurance/',
            dashboard: '/insurance'
        },
        aepsTxn: {
            name: 'aeps',
            base: '/aeps/',
            miniStmt: '/aeps/get-statement',
            checkBalance: '/aeps/check-balance',
            withdrawCash: '/aeps/cash-withdrawal',
            adhaarPay: '/aeps/aadhaar-pay'
        },
        onboarding: {
            personalInfo: '/onboarding/personal-info',
            userKyc: '/onboarding/user-kyc'
        },
        onboardingFinal: {
            shopInfo: '/complete/shop-info',
            kycDoc: '/complete/kyc-docs',
            videoKyc: '/complete/video-kyc'
        }
    }
}