import axios from "axios";
import { toast } from "react-toastify";
import { apiStatusCodes, apiStatusText, API_AREA_VALUE, baseUrl } from "../constants/apiUrls";
import { hashHandler } from "../helpers/functions";
import { userAgentInfo } from "../utils/user_agent";
import { logoutExplicit, storeUnderMaintenance, userToken } from "./storageService";

export const postRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            // const csrfToken = await axios.request({
            //     method: 'get',
            //     url: `${baseUrl}${apiUrls.sanctum}`,
            // });


            // if (csrfToken.status !== 200 && csrfToken.status !== 204) {
            //     toast('CSRF token missmatch ! Please reload the page.', { type: 'error' });
            //     return;
            // }

            // await axios.request({
            //     method: 'get',
            //     url: `${baseUrl}${apiUrls.sanctum}`,
            // })
            //     .then(async () => {

            // if ((params?.payload?.area === undefined || params?.payload?.area === null || params?.payload?.area === '') &&
            //     params?.contentType !== 'multipart/form-data'
            // )

            const requestMethod = (params?.method) ? params.method : 'POST';

            if (params?.contentType !== 'multipart/form-data') {
                params = {
                    url: params.url,
                    payload: {
                        ...params.payload,
                        area: API_AREA_VALUE,
                        reference: userAgentInfo()
                    }
                }
            }

            const endPoint = params.url?.split('?')[0]?.split('/').pop();

            await axios.request({
                method: requestMethod,
                url: `${baseUrl}${params.url}?area=${API_AREA_VALUE}`,
                data: params?.payload,
                headers:
                {
                    Authorization: userToken() ? `Bearer ${userToken()}` : '',
                    requestHash: hashHandler(endPoint),
                    "Content-Type": (params?.contentType) ? params.contentType : "application/json",
                    "accept": "application/json",
                    "Access-Control-Allow-Origin": ["https://*.bharatatm.app"],
                    "withCredentials": true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                },
            }).then((response) => {

                switch (response.status) {
                    case 200:
                        if (response.data.code === apiStatusCodes.successX || response.data.status === 'UNAUTHORIZED') {
                            // success({ authfailed: true });
                            toast(response?.data?.message || 'Session Expired. Please Login.', { type: 'error' });
                            logoutExplicit();
                            window.location.replace('/');
                        } else if (response?.data?.status === apiStatusText.maintenance) {
                            // logoutExplicit();
                            storeUnderMaintenance(response?.data?.message || 'We are under maintenance, Please try after some time');
                            window.location.replace('/');
                        } else if (response.data.code === apiStatusCodes.success) {
                            success({ success: response.data?.data ? response.data.data : response.data });
                        } else if (response.data.code === apiStatusCodes.pending) {
                            success({ pending: response.data?.data ? response.data.data : response.data });
                        } else if (response.data.code === apiStatusCodes.failed) {
                            success({ failed: response.data });
                        } else if (response.data.code === apiStatusCodes.warning) {
                            success({ warning: response.data });
                        }

                        break;

                    default:
                        success({ error: response.data });
                }

            }).catch((error) => {
                if (error.response.status === 401) {
                    logoutExplicit();
                    toast('Session Expired. Please Login.', { type: 'error' });
                    window.location.replace('/');
                } else {
                    reject(error);
                }
                // reject(error);
            });
            // })
            // .catch(err => {
            //     reject(err);
            // });
        }
        catch (error) {
            reject(error);
        }
    });
}



export const getRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            // const csrfToken = await axios.request({
            //     method: 'get',
            //     url: `${baseUrl}${apiUrls.sanctum}`,
            // });


            // if (csrfToken.status !== 200 && csrfToken.status !== 204) {
            //     toast('CSRF token missmatch ! Please reload the page.', { type: 'error' });
            //     return;
            // }


            if (params?.payload?.area === undefined || params?.payload?.area === null || params?.payload?.area === '') {
                params = { url: params.url, payload: { ...params.payload, area: API_AREA_VALUE } }
            }

            const endPoint = params.url.split('?')[0].split('/').pop();

            await axios.request({
                method: 'GET',
                url: `${baseUrl}${params.url}`,
                params: (params?.payload),
                headers:
                {
                    Authorization: userToken() ? `Bearer ${userToken()}` : '',
                    requestHash: hashHandler(endPoint),
                    "Content-Type": "application/json",
                    "accept": "application/json",
                    "Access-Control-Allow-Origin": ["https://*.bharatatm.app"],
                    "withCredentials": true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                },
            }).then((response) => {

                switch (response.status) {

                    case 200:

                        if (response.data.code === apiStatusCodes.successX || response.data.status === 'UNAUTHORIZED') {
                            toast(response?.data?.message || 'Session Expired. Please Login.', { type: 'error' });
                            logoutExplicit();
                            window.location.replace('/');
                        } else if (response?.data?.status === apiStatusText.maintenance) {
                            // logoutExplicit();
                            storeUnderMaintenance(apiStatusText.maintenance);
                            window.location.replace('/');
                        } else if (response.data.code === apiStatusCodes.success) {
                            // success({ success: response.data.data });
                            success({ success: response.data?.data ? response.data.data : response.data });
                        } else if (response.data.code === apiStatusCodes.failed) {
                            success({ failed: response.data });
                        } else if (response.data.code === apiStatusCodes.pending) {
                            success({ pending: response.data });
                        } else if (response.data.code === apiStatusCodes.warning) {
                            success({ warning: response.data });
                        }
                        break;

                    default:
                        success({ error: response.data });
                }

            }).catch((error) => {
                // console.log('getRequest: ', error.response);
                if (error.response.status === 401) {
                    logoutExplicit();
                    toast('Session Expired. Please Login.', { type: 'error' });
                    window.location.replace('/');
                } else {
                    reject(error);
                }
            });

        }
        catch (error) {
            console.log('getRequest: try_catch: ', error);
            reject(error);
        }
    });
}


export const captureForTxn = () => {
    return new Promise(async (success, reject) => {

        const pid = '<PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" pgCount="2" format="0"   pidVer="2.0" timeout="10000" pTimeout="20000" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>';

        const isFind = { check: false };
        const max = 11120;

        for (let i = 11100; i <= max; i++) {
            let urlStr = `http://localhost:${i}/rd/capture`;
            try {
                await axios({
                    method: 'CAPTURE',
                    url: `${urlStr}`,
                    data: pid,
                }).then((response) => {
                    // console.log('getRequest', response);

                    if (response.status === 200) {
                        success({ success: response.data });
                        isFind.check = true;
                    }

                }).catch((error) => {
                    console.log('captureForTxn: ', error);
                    if (i === max) {
                        reject(error = { message: "RDService is not running. Start the RDService" });
                    }

                    // reject(error);
                });
            } catch (error) {
                console.log('Catch: captureForTxn: ', error);
                // reject(err);
            }

            if (isFind.check) {
                break;
            }

        }

    });
}

export const captureForEkyc = () => {
    return new Promise(async (success, reject) => {

        const pid = '<PidOptions ver="1.0"><Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/></PidOptions>';

        const isFind = { check: false };
        const max = 11120;

        for (let i = 11100; i <= max; i++) {
            let urlStr = `http://localhost:${i}/rd/capture`;
            try {
                await axios({
                    method: 'CAPTURE',
                    url: `${urlStr}`,
                    data: pid,
                }).then((response) => {
                    // console.log('getRequest', response);

                    if (response.status === 200) {
                        success({ success: response.data });
                        isFind.check = true;
                    }

                }).catch((error) => {
                    console.log('captureForkyc: ', error);
                    if (i === max) {
                        reject(error = { msg: "RDService is not running. Start the RDService" });
                    }

                });
            } catch (error) {
                console.log('Catch: captureForEkyc: ', error);
                // reject(err);
            }

            if (isFind.check) {
                break;
            }
        }
    });
}


// export const postRequest = (params) => {
//     return new Promise(async (success, reject) => {
//         try {

//             // const csrfToken = await axios.request({
//             //     method: 'get',
//             //     url: `${baseUrl}${apiUrls.sanctum}`,
//             // });


//             // if (csrfToken.status !== 200 && csrfToken.status !== 204) {
//             //     toast('CSRF token missmatch ! Please reload the page.', { type: 'error' });
//             //     return;
//             // }

//             await axios.request({
//                 method: 'get',
//                 url: `${baseUrl}${apiUrls.sanctum}`,
//             })
//                 .then(async () => {

//                     if (params?.payload?.area === undefined || params?.payload?.area === null || params?.payload?.area === '') {
//                         params = { url: params.url, payload: { ...params.payload, area: API_AREA_VALUE } }
//                     }

//                     // axios.defaults.baseURL = baseUrl;
//                     axios.defaults.headers.post['Conten-Type'] = (params?.contentType) ? params.contentType : "application/json";
//                     axios.defaults.headers.post['Accept'] = "application/json";
//                     axios.defaults.withCredentials = true;

//                     axios.interceptors.request.use(function (config) {
//                         const authToken = userToken();
//                         config.headers.Authorization = authToken ? `Bearer ${authToken}` : ''
//                         config.headers['Access-Control-Allow-Origin'] = ["https://*.bharatatm.app"]
//                         config.headers['Access-Control-Allow-Origin'] = 'XMLHttpRequest'
//                         return config
//                     })

//                     await axios.request({
//                         method: 'POST',
//                         url: `${baseUrl}${params.url}`,
//                         // url: `${params.url}`,
//                         data: params?.payload,
//                         // headers: userToken() ?
//                         //     {
//                         //         Authorization: `Bearer ${userToken()}`,
//                         //         "Content-Type": (params?.contentType) ? params.contentType : "application/json",
//                         //         "accept": "application/json",
//                         //         "Access-Control-Allow-Origin": ["https://*.bharatatm.app"],
//                         //         "withCredentials": true,
//                         //         'X-Requested-With': 'XMLHttpRequest'
//                         //         // 'api-key-1': api_key_1,
//                         //         // 'api-key-2': api_key_2
//                         //     } : {},
//                     }).then((response) => {
//                         // console.log(response);

//                         switch (response.status) {
//                             case 200:
//                                 if (response.data.code === apiStatusCodes.successX || response.data.status === 'UNAUTHORIZED') {
//                                     // success({ authfailed: true });
//                                     toast(response?.data?.message || 'Session Expired. Please Login.', { type: 'error' });
//                                     logoutExplicit();
//                                     window.location.replace('/');
//                                 } else if (response.data.code === apiStatusCodes.success) {
//                                     success({ success: response.data?.data ? response.data.data : response.data });
//                                 } else if (response.data.code === apiStatusCodes.pending) {
//                                     success({ pending: response.data?.data ? response.data.data : response.data });
//                                 } else if (response.data.code === apiStatusCodes.failed) {
//                                     success({ failed: response.data });
//                                 }

//                                 break;

//                             default:
//                                 success({ error: response.data });
//                         }

//                     }).catch((error) => {
//                         reject(error);
//                     });
//                 })
//                 .catch(err => {
//                     reject(err);
//                 });
//         }
//         catch (error) {
//             reject(error);
//         }
//     });
// }