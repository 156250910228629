import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LoginContainer from "../LoginContainer";
import LoaderCustom from "../../../components/custom/LoaderCustom";
import Auth from "./Login/Auth";
import SubmitOtp from "./Login/SubmitOtp";
import { userToken } from "../../../services/storageService";
import { appUrls } from "../../../constants/appUrls";
import "./GetStarted.scss";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import { NOCAPTCHA_SITEKEY } from "../../../constants/defaultValues";


const GetStarted = () => {

    const [loginSteps, setLoginStep] = useState('1')
    const [isLoading, setIsLoading] = useState(false);
    const [loginResponse, setLoginResponse] = useState({})
    const navigate = useNavigate();


    useEffect(() => {
        if (userToken()) {
            navigate(appUrls.user.dashboard)
        }
    }, [])


    return (
        <>
            {/* <GoogleReCaptchaProvider reCaptchaKey={NOCAPTCHA_SITEKEY}> */}
            {isLoading && <LoaderCustom />}
            <LoginContainer
                nav={<>
                    <h5 className="text-end">
                        <span className="text-end f16 me-3" onClick={() => navigate('/about-us')} style={{ cursor: 'pointer' }}>About Us</span>
                        <span className="text-end f16 ms-3" onClick={() => navigate('/contact-us')} style={{ cursor: 'pointer' }}>Contact Us</span>
                    </h5>
                </>
                }
                asideContent={
                    <div className="img d-flex justify-content-center align-items-center">
                        <img src="./img/Create free account Copy.svg" alt="" />
                    </div>
                }
                mainContent={
                    <>
                        {loginSteps === '1' && (<Auth setLoginStep={setLoginStep} setIsLoading={setIsLoading} setLoginResponse={setLoginResponse} />)}
                        {loginSteps === '2' && (<SubmitOtp setLoginStep={setLoginStep} setIsLoading={setIsLoading} loginResponse={loginResponse} setLoginResponse={setLoginResponse} />)}
                    </>
                }
            />
            {/* </GoogleReCaptchaProvider> */}
        </>
    )
}

export default GetStarted;
